<template>
  <CRow>
    <CCol>
      <CCard>
        <CCardHeader><font-awesome-icon icon="pencil-ruler" fixed-width /> <strong>預約紀錄</strong> </CCardHeader>
        <CCardBody>
          <div class="mb-3 d-flex justify-content-end">
            <CButton type="button" color="primary" @click="week.show = true">週間設定</CButton>
          </div>

          <v-calendar class="custom-calendar max-w-full" :masks="masks" :attributes="attributelist" @update:to-page="changePage" disable-page-swipe is-expanded>
            <template v-slot:day-content="{ day, attributes }">
              <div class="flex flex-col h-full z-10 overflow-hidden">
                <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
                <div class="flex-grow">
                  <div v-for="attr in attributes" :key="attr.key">
                    <b-badge class="text-wrap text-left" :variant="attr.customData.variant" @click="attributeClick(day.date, attr.customData.id)" role="button">
                      {{ attr.customData.title }}
                    </b-badge>
                  </div>

                  <!-- <p  class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1" :class="attr.customData.class">{{ attr.customData.title }}6</p> -->
                </div>
              </div>
            </template>
          </v-calendar>
        </CCardBody>
      </CCard>

      <b-modal v-model="week.show" title="週間設定">
        <label>星期</label>
        <CSelect :options="week.options" placeholder="請選擇" :value.sync="week.week" />
        <label>可預約數</label>
        <CInput v-model.number="week.limit" type="number" :disabled="week.remove" />
        <CInputCheckbox :custom="true" :checked.sync="week.remove" label="使用預設可預約數"></CInputCheckbox>
        <template #modal-footer>
          <b-button variant="primary" class="float-right" @click="createWeek()"> 設定 </b-button>
        </template>
      </b-modal>

      <b-modal v-model="day.show" :title="day.date + '日期設定'">
        <label>可預約數</label>
        <CInput v-model.number="day.limit" type="number" :disabled="day.remove" />
        <CInputCheckbox :custom="true" :checked.sync="day.remove" label="使用預設可預約數"></CInputCheckbox>
        <template #modal-footer>
          <b-button variant="primary" class="float-right" @click="createDay()"> 設定 </b-button>
        </template>
      </b-modal>

      <b-modal v-model="reserve.show" title="預約詳細資訊">
        <b-overlay :show="reserve.loading" spinner-variant="primary" spinner-type="grow">
          <div v-if="reserve.data">
            <small class="text-muted"> 預約日期 </small>
            <div class="h5 mb-3 font-weight-bold">{{ $date(reserve.data.date).format("YYYY 年 MM 月 DD 日") }}</div>
            <small class="text-muted"> 姓名 </small>
            <div class="h5 mb-3 font-weight-bold">{{ reserve.data.name }}</div>
            <small class="text-muted"> 電話 </small>
            <div class="h5 mb-3 font-weight-bold">{{ reserve.data.phone }}</div>
            <small class="text-muted"> 地址 </small>
            <div class="h5 mb-3 font-weight-bold">{{ reserve.data.county }}{{ reserve.data.district }}{{ reserve.data.address }}</div>
            <small class="text-muted"> Line名稱 </small>
            <div class="h5 mb-3 font-weight-bold">{{ reserve.data.displayName }}</div>
            <small class="text-muted"> 預約建立時間 </small>
            <div class="h5 mb-3 font-weight-bold">{{ $date(reserve.data.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</div>
            <div v-if="reserve.service">
              <small class="text-muted"> 地點類型 </small>
              <div class="h5 mb-3 font-weight-bold">{{ reserve.service.type }}</div>
              <small class="text-muted"> 施工地點狀況 </small>
              <div class="h5 mb-3 font-weight-bold">{{ reserve.service.locationStatuses.join('、') }}</div>
              <small class="text-muted"> 選擇服務 </small>
              <div class="h5 mb-3 font-weight-bold">{{ reserve.service.service }}</div>
              <div v-for="item in reserve.service.content" :key="item.title">
                <small class="text-muted"> {{ item.title }} </small>
                <div class="h5 mb-3 font-weight-bold" v-if="item.type === 0">{{ item.value }}</div>
                <div class="h5 mb-3 font-weight-bold" v-if="item.type === 1">{{ item.value.join('、') }}</div>
              </div>
            </div>
          </div>
        </b-overlay>
        <template #modal-footer>
          <b-button variant="primary" class="float-right" @click="reserve.show = false"> 關閉 </b-button>
        </template>
      </b-modal>
    </CCol>
  </CRow>
</template>

<script>
import Calendar from "v-calendar/lib/components/calendar.umd";
export default {
  name: "",
  components: {
    "v-calendar": Calendar,
  },
  data: () => ({
    calendar: {
      start: null,
      end: null,
    },
    reserves: [],
    limit: 0,
    availableDay: 0,
    rules: [],
    masks: {
      weekdays: "WWW",
    },
    week: {
      show: false,
      week: 0,
      limit: 0,
      remove: false,
      options: [
        { value: 0, label: "星期日" },
        { value: 1, label: "星期一" },
        { value: 2, label: "星期二" },
        { value: 3, label: "星期三" },
        { value: 4, label: "星期四" },
        { value: 5, label: "星期五" },
        { value: 6, label: "星期六" },
      ],
    },
    day: {
      date: null,
      show: false,
      limit: 0,
      remove: false,
    },
    reserve: {
      loading: false,
      show: false,
      id: 0,
      data: null,
      service: null,
    },
  }),
  computed: {
    attributelist() {
      if (this.calendar.start === null) return [];
      let result = [];
      let days = this.$date(this.calendar.start).endOf("month").date();
      let dates = [...Array(days).keys()].map((i) => i + 1);
      dates.forEach((item) => {
        let limit = this.limit;
        let weekrule = this.rules.find((p) => p.week != null && this.$date(this.calendar.start).date(item).day() === p.week);
        if (weekrule) limit = weekrule.limit;
        let daterule = this.rules.find((p) => p.date != null && this.$date(p.date).date() === item);
        if (daterule) limit = daterule.limit;

        let reserves = this.reserves.filter((p) => this.$date(p.date).date() === item);

        reserves.forEach((item) => {
          result.push({
            customData: {
              title: item.name + " / " + item.county + item.district,
              variant: "primary",
              id: item.id,
            },
            dates: this.$date(item.date).toDate(),
          });
        });

        let count = limit - reserves.length;
        count = count >= 0 ? count : 0;

        result.push({
          customData: {
            title: "剩餘可預約數：" + count,
            variant: "secondary",
          },
          dates: this.$date(this.calendar.start).date(item).toDate(),
        });
      });

      return result;
    },
  },
  mounted() {},
  methods: {
    init() {
      this.loading = true;
      this.$http.get("/reserve", { params: { start: this.calendar.start, end: this.calendar.end } }).then((response) => {
        if (response.data.success) {
          this.availableDay = response.data.result.availableDay;
          this.limit = response.data.result.limit;
          this.rules = response.data.result.rules;
          this.reserves = response.data.result.reserves;
        } else {
          this.$notify({ type: "error", text: response.data.message });
        }
        this.loading = false;
      });
    },
    createWeek() {
      this.$http.post("/reserve/rule", { week: this.week.week, limit: this.week.remove ? -1 : this.week.limit }).then((response) => {
        if (response.data.success) {
          this.$notify({ type: "success", text: "設定成功" });
        } else {
          this.$notify({ type: "error", text: response.data.message });
        }
        this.week.show = false;
        this.week.week = 0;
        this.week.limit = 0;
        this.week.remove = false;

        this.init();
      });
    },
    createDay() {
      this.$http.post("/reserve/rule", { date: this.$date(this.day.date).format(), limit: this.day.remove ? -1 : this.day.limit }).then((response) => {
        if (response.data.success) {
          this.$notify({ type: "success", text: "設定成功" });
        } else {
          this.$notify({ type: "error", text: response.data.message });
        }
        this.day.show = false;
        this.day.date = null;
        this.day.limit = 0;
        this.day.remove = false;

        this.init();
      });
    },
    getReserveDetail(id) {
      this.reserve.show = true;
      this.reserve.loading = true;
      this.$http.get("/reserve/detail", { params: { id: id } }).then((response) => {
        if (response.data.success) {
          this.reserve.data = response.data.result;
          this.reserve.service = JSON.parse(this.reserve.data.service);
        } else {
          this.$notify({ type: "error", text: response.data.message });
        }
        this.reserve.loading = false;
      });
    },
    attributeClick(date, id) {
      if (id) {
        this.getReserveDetail(id);
      } else {
        let daterule = this.rules.find((p) => p.date != null && this.$date(p.date).date() === this.$date(date).date());
        this.day.limit = daterule ? daterule.limit : 0;
        this.day.remove = false;
        this.day.date = this.$date(date).format("YYYY/MM/DD");
        this.day.show = true;
      }
    },
    changePage(data) {
      let date = new Date(data.year, data.month - 1, 1);
      this.calendar.start = this.$date(date).startOf("month").format();
      this.calendar.end = this.$date(date).endOf("month").format();
      this.init();
    },
  },
};
</script>

